import React from "react"

import Layout from '../../components/layout'

import { Hero } from '../../components/hero'
import { WhyBodyMedGroup } from '../../components/whyBodyMed'
import { MostPopular } from '../../components/mostPopular'
import { Newsletter } from '../../components/common/newsletter' 
import { CustomerComments } from '../../components/customerComments'
import { ArticleSlider } from '../../components/blog/articleSlider'
import Seo from '../../components/seo'

const IndexPageLodz = () => {
  

  return (
  <Layout lodz>
    <Seo
      title="Klinika Medycyny Estetycznej oraz Centrum Fiteness Łódź - BodyMed Group"
      description="Klinika medycyny estetycznej stworzona dla kobiet i mężczyzn, którzy pragną wymodelować sylwetkę, spalić tłuszcz oraz skorzystać z szerokiego wachlarzu zabiegów na twarz i ciało."
      url="https://bodymedgroup.pl/lodz"
    />
    <Hero lodz/>
    <WhyBodyMedGroup lodz/>
    <MostPopular lodz/>
    <Newsletter/>
    <CustomerComments/>
    <ArticleSlider lodz/>
  </Layout>
)}

export default IndexPageLodz
